<!--

      This view opens when clicking the profile tab. It displays a user's
      profile settings and allows to navigate to the specific settings.

 -->

<template>
  <Page title='Profil' hideback="true">

    <div v-if="$store.state.profile" class="section-title">
      {{ $store.state.profile.email }}
    </div>

    <div class="flex">
      <div class="household-box" @click="$store.dispatch('goTo', 'household')">
        <Icon name="people" class="info-tile-2 household-icon" />
        <div class="household-size-box">
          <div v-if="this.$store.state.profile && this.$store.state.profile.adults != null">
            <Icon 
              v-for="index in Math.min(this.$store.state.profile.adults, 3)" 
              :key="index"
              name="foodableCookHead" 
              class="icon-adult" 
            />
            <span v-if="this.$store.state.profile.adults > 3">...</span>
          </div>
          <div v-if="this.$store.state.profile && this.$store.state.profile.children != null">
            <Icon 
              v-for="index in Math.min(this.$store.state.profile.children, 3)" 
              :key="index"
              name="foodableCookHead" 
              class="icon-child" 
            />
            <span v-if="this.$store.state.profile.children > 3">...</span>
          </div>
        </div>
      </div>

      <div class="info-tiles info-tiles-wrap">
        <div class="info-tile-2" @click="$store.dispatch('goTo', 'orders')">
          <div class="secondary-text">Meine Bestellungen</div>
          <div class="secondary-text-gray settings-text">
            {{ noOfOrders }} mal bestellt
          </div>
          <img class="info-tile-icon profile-info-icon" src="../../assets/img/icon-shopping-bag.svg">
        </div>
        <div class="info-tile-2" @click="$store.dispatch('goTo', 'supermarket')">
          <div class="secondary-text">Lieferung</div>
          <div class="secondary-text-gray settings-text" v-if="this.$store.state.profile && this.$store.state.profile.postal_code">
            {{ $store.state.profile.postal_code }}
          </div>
          <div class="secondary-text-gray settings-text" v-if="this.$store.state.profile && this.$store.state.profile.preferred_store_branch">
            <img class="supermarket-logo" :src="constructImageUrl()" alt="">
          </div>
        </div>
        <div class="info-tile-2" @click="$store.dispatch('goTo', 'payment')" v-if="$store.getters.useInternalPayment">
          <div class="secondary-text">Bezahlmethode</div>
          <div class="secondary-text-gray settings-text" v-if="$store.state.neworderPaymentMethod">
            {{ $store.state.paymentMethodTypes[$store.state.neworderPaymentMethod.type].name }}
          </div>
          <div v-else class="secondary-text-gray settings-text">
            Keine
          </div>
          <Icon name="cardOutline" class="info-tile-icon profile-info-icon" />
        </div>
      </div>

      <div class="flex-item">
        <div class="section-title">Ernährung</div>
        <div class="info-tiles info-tiles-wrap">
          <div class="info-tile-2" @click="$store.dispatch('goTo', 'exclude')">
            <div class="secondary-text">Das essen wir nicht</div>
            <div class="secondary-text-gray settings-text" v-if="$store.getters.profileExclusionsCounter">
              {{ $store.getters.profileExclusionsCounter }} ausgewählt
            </div>
            <div class="secondary-text-gray settings-text" v-if="!$store.getters.profileExclusionsCounter">
              Keine Zutaten ausgeschlossen
            </div>
            <Icon name="heartDislikeOutline" class="info-tile-icon profile-info-icon" />
          </div>
          <div class="info-tile-2" @click="$store.dispatch('goTo', 'bio')">
            <div class="secondary-text">Produktqualität</div>
            <div class="secondary-text-gray settings-text" v-if="$store.state.profile && $store.state.profile.ingredient_categories_bio">
              {{ $store.state.profile.ingredient_categories_bio.length }} ausgewählt
            </div>
            <Icon name="leafOutline" class="info-tile-icon profile-info-icon" />
          </div>
          <div class="info-tile-2" @click="$store.dispatch('goTo', 'recipecategories')">
            <div class="secondary-text">Rezeptkategorien</div>
            <div class="secondary-text-gray settings-text" v-if="$store.state.selectedRecipeCategories && $store.state.selectedRecipeCategories.length">
              {{ $store.state.selectedRecipeCategories.filter(c => c.selectable).length }} ausgewählt
            </div>
            <div v-else class="secondary-text-gray settings-text">Keine</div>
            <Icon name="foodableDish" class="info-tile-icon profile-info-icon" />
          </div>
        </div>
      </div>

      <div class="flex-item">
        <div class="section-title">Benachrichtigungen</div>
        <div class="info-tiles info-tiles-wrap">
          <div class="info-tile-2" @click="$store.dispatch('goTo', 'orderday')">
            <div>
              <div class="secondary-text">Bestell-Erinnerung</div>
              <div class="secondary-text-gray settings-text" v-if="$store.getters.orderDayNotificationOn && $store.state.profile.order_time">
                jede Woche {{ $store.getters.orderDayShort }} {{ $store.state.profile.order_time.split(":")[0] }}:{{ $store.state.profile.order_time.split(":")[1] }} Uhr
              </div>
              <div class="secondary-text-gray settings-text" v-if="!$store.getters.orderDayNotificationOn">
                Nicht aktiv
              </div>
            </div>
            <ion-toggle :checked="$store.getters.orderDayNotificationOn" @click.stop="$store.dispatch('toggleOrderDayNotification')"></ion-toggle>
          </div>
          <div class="info-tile-2" @click="$store.dispatch('goTo', 'foodwaste')">
            <div>
              <div class="secondary-text">Lebensmittelretter</div>
              <div class="secondary-text-gray settings-text" v-if="$store.getters.foodwasteNotificationOn">
                an jedem ersten {{ $store.getters.foodwasteDayShort }} im Monat
              </div>
              <div class="secondary-text-gray settings-text" v-if="!$store.getters.foodwasteNotificationOn">
                Nicht aktiv
              </div>
            </div>
            <ion-toggle :checked="$store.getters.foodwasteNotificationOn" @click.stop="$store.dispatch('toggleFoodwasteNotification')"></ion-toggle>
          </div>
        </div>
      </div>
    </div>
    <div class="flex">
      <div>
        <div class="section-title">Datenschutz</div>
        <div class="white-box text-buttons-box">
          <div class="list-item" @click="this.$store.dispatch('openLegalDocument', 'imprint')">
            <div class="profile-list-btn">
              <Icon name="documentTextOutline" class="profile-list-icon" />
              <div class="main-text">Impressum</div>
            </div>
          </div>
          <div class="list-item" @click="this.$store.dispatch('openLegalDocument', 'privacy')">
            <div class="profile-list-btn">
              <Icon name="shieldCheckmarkOutline" class="profile-list-icon" />
              <div class="main-text">Datenschutzerklärung</div>
            </div>
          </div>
          <div class="list-item" @click="this.$store.dispatch('openLegalDocument', 'agb')">
            <div class="profile-list-btn">
              <Icon name="documentsOutline" class="profile-list-icon" />
              <div class="main-text">Allg. Nutzungsbedingungen</div>
            </div>
          </div>
          <div v-if="!this.$store.state.notificationsEnabled" class="list-item" @click="this.$store.dispatch('askForNotificationPermission')">
            <div class="profile-list-btn">
              <Icon name="notificationsOutline" class="profile-list-icon" />
              <div class="main-text">Notifications aktivieren</div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="section-title">Mein Konto</div>
        <div class="white-box text-buttons-box">
          <div class="list-item" v-if="this.$store.getters.debugmode" @click="$store.dispatch('goTo', 'onboarding')">
            <div class="profile-list-btn">
              <Icon name="readerOutline" class="profile-list-icon" />
              <div class="main-text">Onboarding-Fragebogen starten</div>
            </div>
          </div>
          <div v-if="allowSwitchingCustomerJourney">
            <div class="list-item" v-if="!this.$store.getters.oldCustomerJourney" @click="switchCustomerJourney('old')">
              <div class="profile-list-btn">
                <Icon name="albumsOutline" class="profile-list-icon" />
                <div class="main-text">Kochbox-Modus aktivieren</div>
              </div>
            </div>
            <div class="list-item" v-if="this.$store.getters.oldCustomerJourney" @click="switchCustomerJourney('new')">
              <div class="profile-list-btn">
                <Icon name="albumsOutline" class="profile-list-icon" />
                <div class="main-text">Kochbox-Modus deaktivieren</div>
              </div>
            </div>
          </div>
          <div class="list-item" @click="this.$store.dispatch('writeHelpMail')">
            <div class="profile-list-btn">
              <Icon name="mailOutline" class="profile-list-icon" />
              <div class="main-text">Ich brauche Hilfe / habe Feedback</div>
            </div>
          </div>
          <div class="list-item" @click="this.$store.dispatch('openModal', { name: 'deleteaccount' })">
            <div class="profile-list-btn">
              <Icon name="trashOutline" class="profile-list-icon" />
              <div class="main-text">Konto vollständig löschen</div>
            </div>
          </div>
        </div>
        <div class="white-box text-buttons-box">
            <div class="list-item" @click="this.$store.dispatch('logout')">
            <div class="profile-list-btn">
              <Icon name="powerOutline" class="profile-list-icon" />
              <div class="main-text">Abmelden</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Price from '@/components/Price.vue'
import { IonToggle } from '@ionic/vue'

export default {
  name: 'profile',
  components: {
    Page,
    Price,
    IonToggle,
  },
  data () {
    return {
      paymentMethodTypes: {
        sepa_debit: {icon: 'logoEuro', name: 'SEPA Lastschrift'},
        card: {icon: 'card', name: 'Kreditkarte'}
      }
    }
  },
  computed: {
    noOfOrders () {
      if (this.$store.state.orders) {
        return this.$store.state.orders.count
      } else {
        return 0
      }
    },
    allowSwitchingCustomerJourney () {
      if (this.$store.state.profile) {
        // only allow existing users to switch customer journeys for now. Mainly to get clean test data.
        const newCustomerJourneyRelease = new Date('2021-12-08T00:00:00')
        const profileCreatedAt = new Date(this.$store.state.profile.created_at)
        return profileCreatedAt < newCustomerJourneyRelease
      }
    },
  },
  methods: {
    constructImageUrl() {
      if (this.$store.state.profile && this.$store.state.profile.preferred_store_branch) {
        return process.env.VUE_APP_IMAGE_CDN + process.env.VUE_APP_IMAGE_FILTER5 + this.$store.state.profile.preferred_store_branch.store_logo
      }
    },
    async switchCustomerJourney (customerJourney) {
      if (customerJourney === 'old') {
        await this.$store.dispatch('applySettings', { key: 'old_customer_journey', value: true })
        this.$store.dispatch('logFoodableAnalyticsEvent', { name: 'switched_customer_journey_to_old' })
      }
      else if (customerJourney === 'new') {
        await this.$store.dispatch('applySettings', { key: 'old_customer_journey', value: false })
        this.$store.dispatch('logFoodableAnalyticsEvent', { name: 'switched_customer_journey_to_new' })
      }
      this.$store.dispatch('reloadApp')
    }
  },
  mounted () {
    this.$store.dispatch('save', { key: 'tab', value: 'profile' })
    this.$store.dispatch('logAnalyticsEvent', { name: 'opened_profile' })
    this.$store.dispatch('checkIfNotificationsEnabled')
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.household-box {
  background: white;
  border-radius: $border-radius;
  margin: 0 20px 20px 20px;
  box-shadow: $box-shadow;
  display: flex;
  height: calc(50vw - 51px);
  max-height: #{$tile-max-size};
  max-width: calc(#{$tile-max-size} * 2 + 20px);
}

.household-icon {
  --stroke: white;
  fill: white;
  background: $col-lighter-gray-font;
  border-radius: $border-radius;
  padding: 15px;
  box-shadow: none;
}

.household-size-box {
  @extend .info-tile-2;
  padding: 20px;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
  box-shadow: none;
}

.icon-adult, .icon-child {
  display: inline-block;
  fill: #{$col-font-green};
  height: auto;
}

.icon-adult {
  width: 35px;
  margin: 3px 0;
}

.icon-child {
  width: 25px;
  margin: 3px;
}

.icon-area {
  height: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon-circle {
  width: 50vw;
  height: 50vw;
  background: white;
  border-radius: 500px;
  margin: auto;
  padding: 20px;
  position: relative;
  box-shadow: $box-shadow;
}

.profile-info {
  color: #{$col-main-green} !important;
  text-align: right;
  margin-left: auto;
  max-width: 100px;
}

.profile-list-btn {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 8px 0;
  svg {
    margin-right: 5px;
    height: 20px;
    width: 20px;
  }
}

.profile-list-icon {
  width: 24px;
  height: 24px;
  --stroke: #{$col-font};
  margin-right: 8px;
}

.profile-info-icon {
  width: 30px;
  height: auto;
  margin-top: 10px;
  --stroke: #{$col-font-green};
  stroke: #{$col-font-green};
  fill: #{$col-font-green};
}

.settings-text {
  margin: 10px;
  line-height: 1.5rem;
}

.supermarket-logo {
  width: 50%;
  height: auto;
  border-radius: 4px;
}

.text-items {
  max-width: 400px;
}

.text-buttons-box {
  max-width: calc(2 * #{$tile-max-size} + 20px);
}
</style>
